<template>
  <b-card>
    <action-btn
      :selected-number="selectedNumber"
      :actions="['createBtn', 'editorBtn', 'deleteBtn' , 'reFresh']"
      @add-item="addCommom"
      @delete-item="$refs.confirmDelete.showModal()"
      @refresh-page="refreshPage"
    />
    <good-table
      ref="bieuMau"
      class="mt-1"
      :columns="columns"
      :rows="danhSachBieuMau"
      :total="tongSoBieuMau"
      @selected-item="onSelectRow"
      @column-filter="filterdanhSachBieuMau"
      @page-change="pageChange"
      @update-item="toUpdateForm"
      @delete-item="setItemUpdate"
    >
      <template
        slot="custom-filter"
        slot-scope="props"
      >
        <date-picker
          v-if="props.props.column.field === 'lastModifiedOnDate'"
          v-model="dateFilter"
          range
          class="w-100 picker-filter"
          format="DD/MM/YYYY"
          @change="filterdanhSachBieuMau()"
        />
        <treeselect
          v-else-if="props.props.column.field === 'stateDisplay'"
          id="linhVuc"
          v-model="filter.state"
          v-format-v-select
          class="vtreeselect-chooser"
          :options="trangThaiOptions"
          :limit="0"
          :limit-text="(count) => ` + ${count} lựa chọn`"
          no-options-text="Không có dữ liệu"
          no-results-text="Không có dữ liệu"
          placeholder="Chọn loại trạng thái"
          :clear-on-select="true"
          @input="filterdanhSachBieuMau()"
        />
      </template>
      <template
        slot="custom-row"
        slot-scope="props"
      >
        <span v-if="props.props.column.field === 'lastModifiedOnDate'">
          {{ formatDate(props.props.row.lastModifiedOnDate) }}
        </span>
        <router-link
          v-else-if="props.props.column.field === 'name'"
          :to="`${ROUTE_NAME.NAME + ROUTE_NAME.FORM.CHI_TIET}/${props.props.row.id}`"
        >
          {{ props.props.row.name }}
        </router-link>
      </template>
      <template
        slot="action"
        slot-scope="props"
      >
        <copy-icon
          v-if="props.props.column.field === 'actions'"
          v-b-tooltip.hover.v-secondary
          title="Sao chép bản ghi"
          size="16"
          class="custom-class cursor-pointer mr-1"
          @click="onDuplicate(props.props.row)"
        />
      </template>
    </good-table>
    <common-modal
      ref="confirmDelete"
      title="Xác nhận"
      size="sm"
      @handle-ok="onDelete"
    />
  </b-card>
</template>
<script>
import {
  BCard, VBTooltip,
} from 'bootstrap-vue'
import addCssSemantic from '@/mixins/mixins'
import ActionBtn from '@/modules/danh-muc/components/common/ActionBtn.vue'
import CommonModal from '@/modules/danh-muc/components/modal/CommonModal.vue'
import GoodTable from '@/components/GoodTable.vue'
import { required } from '@validations'
import END_POINTS from '@/api/endpoints'
import { ROUTE_NAME } from '@/modules/quan-tri-he-thong/constants/constants'
import { formatDate, formatDateRequest } from '@/utils/index'
import { CopyIcon } from 'vue-feather-icons'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    ActionBtn,
    GoodTable,
    BCard,
    CommonModal,
    CopyIcon,
    DatePicker,
    Treeselect,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      ROUTE_NAME,
      required,
      title: 'Thêm mới báo cáo',
      isCreate: true,
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
        },
        {
          label: 'Tên biểu mẫu',
          field: 'name',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
          width: 'auto',
        },
        {
          label: 'Trạng thái',
          field: 'stateDisplay',
          width: '180px',
          thClass: 'text-center',
          tdClass: 'text-left',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Thao tác',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
          field: 'actions',
          width: '100px',
        },
      ],
      filter: {
        page: 1,
        size: 10,
        state: null,
      },
      selectedRows: [],
      selectedNumber: 0,
      danhSachBieuMau: [],
      tongSoBieuMau: 0,
      dateFilter: null,
      trangThaiOptions: [
        {
          id: 'DRAFT',
          label: 'Bản nháp',
          name: 'DRAFT',
        },
        {
          id: 'APPROVED',
          label: 'Công khai',
          name: 'APPROVED',
        },
      ],
    }
  },
  created() {
    this.filterdanhSachBieuMau()
  },
  methods: {
    formatDate,
    pageChange() {
      const data = this.$refs.bieuMau.getCurrentPage()
      const payload = {
        ...this.filter,
        page: data.pageNumber,
        size: data.pageSize,
      }
      this.$axios.post(END_POINTS.BIEU_MAU.filterColumns(), payload, false).then(response => {
        this.danhSachBieuMau = response.data.data.content
        this.tongSoBieuMau = response.data.data.totalElements
      })
    },
    filterdanhSachBieuMau(param = {}) {
      this.filter = {
        ...this.filter,
        ...param,
        tenBang: param.tableName ? param.tableName.replace(/\s+/g, ' ').trim() : '',
        page: 1,
        size: this.filter.size,
        ngaySuaStart: this.dateFilter ? formatDateRequest(this.dateFilter[0]) : '',
        ngaySuaEnd: this.dateFilter ? formatDateRequest(this.dateFilter[1]) : '',
      }
      let payload = {
        ...this.filter,
      }
      if (this.$refs.bieuMau) {
        payload = {
          ...payload,
          ...this.$refs.bieuMau.getCurrentPage(),
        }
      }
      if (param.canCuPhapLy) {
        payload = {
          ...payload,
          canCuPhapLy: param.canCuPhapLy ? param.canCuPhapLy.replace(/\s+/g, ' ').trim() : '',
        }
      }
      if (param.name) {
        payload = {
          ...payload,
          name: param.name ? param.name.replace(/\s+/g, ' ').trim() : '',
        }
      }
      payload = {
        ...payload,
        page: 1,
        size: this.filter.size,
      }
      this.$axios.post(END_POINTS.BIEU_MAU.filterColumns(), this.$trimObject({
        ...payload,
      }), false).then(response => {
        this.danhSachBieuMau = response.data.data.content
        this.tongSoBieuMau = response.data.data.totalElements
      })
    },
    resetFilter() {
      this.filter = {
        page: 1,
        size: 10,
        state: null,
      }
    },
    refreshPage() {
      this.$refs.bieuMau.$refs.vbTables.reset()
      if (this.filter.name || this.filter.state === null) {
        this.resetFilter()
        this.filterdanhSachBieuMau()
      } else {
        this.resetFilter()
      }
    },
    clearFilter(field) {
      const found = this.columns.find(c => c.field === field)
      found.filterOptions.filterValue = ''
    },
    onSelectRow(rows) {
      this.selectedRows = rows
      this.selectedNumber = rows.length
    },
    toUpdateForm(row) {
      this.$router.push(`${ROUTE_NAME.NAME + ROUTE_NAME.FORM.SUA}/${row.id}`)
    },
    onDelete() {
      const payload = {
        listId: this.selectedRows.map(row => row.id),
      }
      this.$axios.delete(END_POINTS.BIEU_MAU.URL(), payload).then(res => {
        if (res.data) {
          this.filterdanhSachBieuMau()
        }
      })
    },
    setItemUpdate(item) {
      this.selectedRows = [item]
      this.$refs.confirmDelete.showModal()
    },
    addCommom() {
      this.$router.push(ROUTE_NAME.NAME + ROUTE_NAME.FORM.THEM_MOI)
    },
    onDuplicate(row) {
      this.$axios.post(END_POINTS.BIEU_MAU.cloneBieuMau(row.id)).then(res => {
        if (res.data) {
          this.filterdanhSachBieuMau()
        }
      })
    },
  },
}
</script>
