<template>
  <danh-sach-form />
</template>

<script>
import DanhSachForm from '@/modules/quan-tri-he-thong/components/pages/DanhSachForm.vue'

export default {
  name: 'TrangDanhSachForm',
  components: {
    DanhSachForm,
  },
}
</script>

<style scoped></style>
